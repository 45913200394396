import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { twMerge } from "tailwind-merge";
import Pagination from "./Pagination";
import { Input } from "./ui/input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetRowSelection } from "../redux/reducers/rowSlice";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Download, FolderPlus } from "lucide-react";
import { setPage, setSearch } from "../redux/reducers/filterSlice";
import { Label } from "./ui/label";
import { useForm } from "react-hook-form";
import { createNewFolder } from "../lib/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { privateAxios } from "../lib/axios";
import { CREATEFOLDER, GETIFOLDERDETAILS } from "../constant/endpoints";
import { toastHandler } from "../lib/helper";
import { Dialog, DialogContent } from "./ui/dialog";

const DataTable = ({
  data,
  columns,
  pagination,
  rowSelection = [],
  setRowSelection,
  sorting,
  setSorting,
  sortableColumns,
  downloadCSV = false,
  isFetching = false,
  expanded,
  setExpanded,
  enableSearch = true,
  enableRowSelection = true,
  enableSort = true,
  mainStyle,
  pageSizes,
  downloadUserCSV,
}) => {
  const { search } = useSelector((state) => state.filterReducer);
  const selectedRows = useSelector((data) => data.rowReducer.selectedRows);
  const dispatchParent = useSelector(
    (state) => state.intialFoldersReducer.dispatch
  );
  const returnParent = useSelector(
    (state) => state.intialFoldersReducer.return
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { id } = useParams();
  const [modal, setIsModal] = useState({ open: false, name: null });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!selectedRows.length) {
      return table.resetRowSelection();
    }
  }, [selectedRows]);

  const { mutate } = useMutation({
    mutationFn: (values) => privateAxios(CREATEFOLDER, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
      toastHandler(res);
    },
    onSettled: () => {
      folder.reset();
      setIsModal((prevState) => ({ ...prevState, open: false, name: 'folder' }));
    },
  });

  const folder = useForm({
    resolver: zodResolver(createNewFolder),
    defaultValues: {
      displayName: "",
      parent_folder: "",
    },
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    enableFilters: enableSearch,
    enableRowSelection: enableRowSelection,
    enableSorting: enableSort,
  });

  const onSubmit = (values) => {
    const isDispatch = pathname.includes("/dispatch");

    const body = {
      name: values.displayName,
      parent_folder: isDispatch ? dispatchParent._id : returnParent._id,
    };
    const { displayName: name } = values;
    if (id) {
      return mutate({ name, parent_folder: id });
    } else if (pathname.includes("/return")) {
      return mutate({ name, parent_folder: returnParent._id });
    } else if (pathname.includes("/dispatch")) {
      return mutate({ name, parent_folder: dispatchParent._id });
    } else {
    }

    // mutate(body);
  };

  const handleFolderClicked = (item) => {
    dispatch(resetRowSelection());

    let path = pathname.split("/");

    if (path.includes(id)) {
      path = path
        .map((values) => (values === id ? item._id : values))
        .join("/");
    } else {
      path.push(item._id);
      path = path.join("/");
    }

    item.isFolder === "1" &&
      navigate(path, {
        state: {
          from: state?.from
            ? [
              ...state.from,
              {
                id: item._id,
                name: item.displayName,
              },
            ]
            : [
              {
                id: item._id,
                name: item.displayName,
              },
            ],
        },
      });
  };

  const handleTableHeadClicked = (e, header) => {
    if (header.column.getCanSort())
      if (header.id === "createdAt") {
        if (e.target.innerHTML === "Uploaded on") {
          return header.column.getIsSorted() === "asc"
            ? header.column.toggleSorting(true)
            : header.column.toggleSorting(false);
        } else return;
      } else {
        return header.column.getIsSorted() === "asc"
          ? header.column.toggleSorting(true)
          : header.column.toggleSorting(false);
      }
  };

  const handleClose = () => {
    setIsModal((prevState) => ({
      open: !prevState.open,
      data: null,
    }));
    folder.reset();
  };

  return (
    <>
      <section className="hidden md:block">
        <div className="w-full flex items-center justify-between">
          <div className="w-full flex items-center gap-x-4">
            {enableSearch && (
              <div className="w-full max-w-xs">
                <Input
                  value={search ?? ""}
                  onChange={({ target }) => {
                    dispatch(setPage(1));
                    dispatch(setSearch(target.value));
                  }}
                  placeholder="Search"
                />
              </div>
            )}
          </div>
          <div className="w-full flex items-center justify-end">
            {(pathname.includes("/dispatch") ||
              pathname.includes("/return")) && (
                <Button
                  className="bg-[#0097CECC] hover:bg-bg2-hover text-white hover:text-black items-center gap-x-3"
                  onClick={() =>
                    setIsModal((prevState) => ({ ...prevState, open: true, name: "folder" }))
                  }
                >
                  <span className="pt-[1px] font-semibold">Create Folder</span>
                  <FolderPlus className="w-4 h-4" />
                </Button>
              )}
            {downloadCSV && (
              <Button
                className="bg-bg2-hover text-text items-center gap-x-3"
                onClick={() => downloadUserCSV.mutate()}
              >
                <span className="pt-[1px]">Download CSV</span>
                <Download className="w-4 h-4" />
              </Button>
            )}
          </div>
        </div>
        <Table className="relative" mainStyle={mainStyle}>
          <TableHeader className="sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="hover:bg-transparent border-text"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{ width: header.getSize() + "px" }}
                      className="text-text font-lato font-semibold whitespace-nowrap"
                      onClick={(e) => handleTableHeadClicked(e, header)}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {isFetching ? (
            <TableBody>
              {[...new Array(25)].map((_, index) => {
                return table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <TableRow key={index} className="border-text">
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableCell key={header.id} className="py-2 px-1">
                            <div className="w-full py-[14px] rounded-full bg-gray-200 animate-pulse"></div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          ) : (
            <TableBody className="[&_tr:last-child]:border-1">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="group data-[state=selected]:bg-[#e5ecf9] hover:bg-[#e5ecf9] border-text"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={twMerge(
                          "whitespace-nowrap",
                          cell.row.original.isFolder === "1" && "cursor-pointer"
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          cell.row.original.isFolder === "1" &&
                            cell.column.id === "displayName" &&
                            handleFolderClicked(cell.row.original);
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-16 text-center border-b text-lg"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {pagination && (
          <Pagination
            table={table}
            rowSelection={rowSelection}
            enableRowSelection={enableRowSelection}
            pageSizes={pageSizes}
          />
        )}

        {/* Trash Restore Modal */}
      </section>

      <Dialog open={modal.open} onOpenChange={handleClose}>
        <DialogContent
          closeIcon={false}
          className="w-full max-w-[400px] bg-bg-primary"
        >
          <form
            onSubmit={folder.handleSubmit(onSubmit)}
            className="flex flex-col gap-y-6"
          >
            <div className="flex flex-col gap-y-4">
              <Label className="text-text text-2xl">New Folder</Label>

              <Input
                {...folder.register("displayName")}
                className={`py-5 border ${folder.formState.errors.displayName &&
                  "text-red-400 placeholder:text-red-600 border-red-400"
                  }`}
                autoComplete="off"
                placeholder="Enter folder name"
              />
            </div>
            <div className="flex justify-end gap-x-3">
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DataTable;
