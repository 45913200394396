import React from 'react'
import Logo from '../../assets/images/logoWithSlogan.jpg'
import { useForm } from 'react-hook-form'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { confirmPasswordSchema } from '../../lib/schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { publibAxios } from '../../lib/axios'
import { ADMINRESET_PASSWORD } from '../../constant/endpoints'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toastHandler } from '../../lib/helper'
import md5 from 'md5'

const AdminResetPassword = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const form = useForm({
        resolver: zodResolver(confirmPasswordSchema),
        defaultValues: {
            new_password: "",
            confirm_new_password: ""
        }
    })

    const { mutate } = useMutation({
        mutationFn: (values) => publibAxios(ADMINRESET_PASSWORD, values),
        onSuccess: (res) => {
            if (res.ResponseCode === "1") {
                navigate('/admin/login')
            }
            return toastHandler(res)
        },
        onError: (res) => {
            return toastHandler(res)
        }
    })

    const onSubmit = (values) => {
        mutate({ email: state?.email, new_password: md5(values?.new_password) })
    }

    return (
        <div className='w-full max-w-sm h-screen mx-auto flex flex-col justify-center'>
            <div className='space-y-10'>
                <div className='space-y-2'>
                    <div className='max-w-[350px] mx-auto mb-6'>
                        <img src={Logo} alt="logo" />
                    </div>
                    <h1 className='text-primary text-2xl font-lato font-semibold'>Reset Your Password</h1>
                    <p className='text-text2'> Please enter the details below to reset your password.</p>
                </div>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="w-full h-max space-y-4"
                    >
                        <FormField
                            control={form.control}
                            name="new_password"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-text text-base font-lato">
                                        New password
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Enter new password"
                                            onChange={({ target: { value } }) => form.setValue("new_password", value.toLowerCase())}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="confirm_new_password"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-text text-base font-lato">
                                        Confirm password
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Enter confirm password"
                                            onChange={({ target: { value } }) => form.setValue("confirm_new_password", value.toLowerCase())}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className='pt-5'>
                            <Button className="w-full">Update</Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default AdminResetPassword