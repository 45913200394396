import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useDebounce } from "@uidotdev/usehooks";
import { format } from "date-fns";
import {
  ArrowDown,
  ArrowUp,
  Edit2,
  Minus,
  MoreVertical,
  Trash2,
  X,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import {
  ALLOWCATECREDITS,
  DOWNLOADUSERCSV,
  GETUSERS,
  UPDATECONFIGURATION,
  PLANS,
  UPDATEUSER_PLAN,
  ADMINCHANGEPASSWORD,
  DELETEUSERS,
} from "../../constant/endpoints";
import usePageLeave from "../../hooks/usePageLeave";
import { privateAdminAxios } from "../../lib/axios";
import { dowloadUserCSV, formatFileSize, toastHandler } from "../../lib/helper";
import {
  resetFilter,
  setLimit,
  setTotalPage,
} from "../../redux/reducers/filterSlice";
import { resetRowSelection } from "../../redux/reducers/rowSlice";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import usericon from "../../assets/images/usericon.png";
import DatePicker from "../../components/modals/DatePicker";
import { Label } from "../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { configuration as configurationSchema } from "../../lib/schemas";
import { Textarea } from "../../components/ui/textarea";

const Dashboard = () => {
  const { page, limit, search, start, end } = useSelector(
    (state) => state.filterReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [allowCateCredit, setAllowCateCredit] = useState({
    open: false,
    data: { _id: null, credits: null },
  });
  const [credit, setCredit] = useState({ current: null, add: null });
  const [isCSVDowloading, setIsCSVDowloading] = useState(false);
  const [updateConfig, setUpdateConfig] = useState({ open: false, data: null });
  const [selectedFileFormat, setSelectedFileFormat] = useState("KB");
  const [reason, setReason] = useState('');
  const fileSizes = useMemo(() => ["Bytes", "KB", "MB", "GB", "TB"], []);
  const [deleteAccount, setDeleteAccount] = useState({ open: false, data: null });


  const plans = useQuery({
    queryKey: [PLANS],
    queryFn: () => privateAdminAxios(PLANS, null, "GET"),
    select: (res) => res.data,
  });

  const defaultValues = useMemo(
    () => ({
      allocatePerMonthCredit: "",
      dispatchRate: "",
      returnRate: "",
      allocateStorage: "",
      plan_id: ""
    }),
    []
  );

  const configuration = useForm({
    resolver: zodResolver(configurationSchema),
    defaultValues,
  });


  useEffect(() => {
    if (!updateConfig.data) return;

    const [size, type] = formatFileSize(updateConfig.data.storage).split(" ");

    setSelectedFileFormat(type);

    configuration.reset({
      allocatePerMonthCredit: updateConfig.data.allocatePerMonthCredit,
      dispatchRate: updateConfig.data.dispatchRate,
      returnRate: updateConfig.data.returnRate,
      allocateStorage: size,
      plan_id: configuration.getValues("plan_id")
    });
  }, [updateConfig.data]);

  const onSubmit = (values) => {
    const fileSize = {
      Bytes: 1,
      KB: 1024,
      MB: Math.pow(1024, 2),
      GB: Math.pow(1024, 3),
      TB: Math.pow(1024, 4),
    };
    const { allocateStorage } = values;

    const payload = Object.keys(configuration.formState.dirtyFields).reduce(
      (acc, curr) =>
        Object.assign(acc, {
          [curr]:
            curr === "allocateStorage"
              ? allocateStorage * fileSize[selectedFileFormat]
              : values[curr],
        }),
      {}
    );

    return updateConfiguration.mutate({
      allocatePerMonthCredit: values?.allocatePerMonthCredit,
      allocateStorage: allocateStorage * fileSize[selectedFileFormat],
      dispatchRate: values?.dispatchRate,
      returnRate: values?.returnRate,
      user_id: updateConfig.data._id,
    });
  };

  useEffect(() => {
    dispatch(setLimit(100));
  }, []);

  const debouncedSearch = useDebounce(search, 300);
  usePageLeave(() => {
    dispatch(resetFilter());
    dispatch(resetRowSelection());
  });

  const queryClient = useQueryClient();

  const plan = useQuery({
    queryKey: [PLANS],
    queryFn: () => privateAdminAxios(PLANS, null, "GET"),
    select: res => res.data
  });

  const {
    data: users,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [GETUSERS, page, limit, debouncedSearch, start, end],
    queryFn: () =>
      privateAdminAxios(GETUSERS, { start, end, search }, null, page, limit),
    onSuccess: (res) => {
      return dispatch(setTotalPage(Math.ceil((res.totalRecords ?? 1) / limit)));
    },
    initialData: {},
  });

  const updateConfiguration = useMutation({
    mutationFn: (values) => privateAdminAxios(UPDATECONFIGURATION, values),
    onSuccess: (res) => {
      refetch();
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: closeConfigModal,
  });

  const { mutate } = useMutation({
    mutationFn: (values) => privateAdminAxios(ALLOWCATECREDITS, values),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [GETUSERS] });
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
    onSettled: () => {
      setAllowCateCredit({ open: false, data: { credits: null, _id: null } });
      setCredit({ add: null, current: null });
    },
  });

  const updatePlant = useMutation({
    mutationFn: (values) => privateAdminAxios(UPDATEUSER_PLAN, values),
    onSuccess: (res, payload) => {
      configuration.setValue("plan_id", payload.plan_id)
      toastHandler(res);
    },
    onError: (err) => toast.error(err),
  });

  const downloadUserCSV = useMutation({
    mutationFn: (values) => privateAdminAxios(DOWNLOADUSERCSV, null, "GET"),
    onSuccess: async (res) => {
      setIsCSVDowloading(true);
      try {
        const message = await dowloadUserCSV(res.data);
        toast.success(message);
      } catch (err) {
        toast.error(err);
      } finally {
        setIsCSVDowloading(false);
        dispatch(resetRowSelection());
      }
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const data = users?.data ?? [];

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("profileImage", {
        header: (props) => <span>Profile Image</span>,
        cell: (props) => (
          <div>
            <Avatar>
              <AvatarImage
                src={
                  props.row.original.logo ? props.row.original.logo : usericon
                }
              />
              <AvatarFallback className="uppercase">
                {props.row.original.name
                  ? props.row.original.name.slice(0, 2) ?? ""
                  : ""}
              </AvatarFallback>
            </Avatar>
          </div>
        ),
        enableSorting: false,
        size: 85,
      }),

      columnHelper.display({
        id: "updateConfig",
        cell: (props) => {
          return (
            <Badge
              className="py-[6px] px-4 bg-transparent hover:bg-bg2-hover text-text text-sm cursor-pointer"
              onClick={() => {
                setUpdateConfig((prevState) => ({
                  ...prevState,
                  open: true,
                  data: { ...props.row.original },
                }))
                configuration.setValue("plan_id", props.row.original.plan_id)
              }}
            >
              <MoreVertical />
            </Badge>
          );
        },
      }),

      columnHelper.accessor("email", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent hover:bg-bg2-hover text-text text-sm">
              Email
            </Badge>
            <div className="p-2 rounded-full hover:bg-bg2-hover text-text transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4 text-text" />
              ) : (
                <ArrowUp className="w-4 h-4 text-text" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 220,
      }),

      columnHelper.accessor("name", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent hover:bg-bg2-hover text-text text-sm">
              Name
            </Badge>
            <div className="p-2 rounded-full hover:bg-bg2-hover text-text transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4 text-text" />
              ) : (
                <ArrowUp className="w-4 h-4 text-text" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 180,
      }),

      columnHelper.accessor("phone", {
        header: <span className="text-text">Number</span>,
        cell: (props) => (
          <div>
            <span>+{props.row.original.ccode}</span>
            &nbsp;
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: false,
        size: 180,
      }),

      columnHelper.accessor("plan_id", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent hover:bg-bg2-hover text-text text-sm">
              Active Plan
            </Badge>
            <div className="p-2 rounded-full hover:bg-bg2-hover text-text transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4 text-text" />
              ) : (
                <ArrowUp className="w-4 h-4 text-text" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>
              {props.getValue() ? (
                plans.data?.find((plan) => plan._id === props.getValue())
                  ?.plan_name
              ) : (
                <Minus className="w-4 h-4 text-text" />
              )}
            </span>
          </div>
        ),
        enableSorting: true,
        size: 180,
      }),

      columnHelper.accessor("createdAt", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Joined Date
            </Badge>
            <DatePicker table={props} />
          </div>
        ),
        cell: (props) => (
          <span className="pl-4">
            {format(new Date(props.getValue()), "dd MMM yyyy")}
          </span>
        ),
        enableSorting: true,
        size: 90,
      }),

      // columnHelper.accessor("updatedAt", {
      //   header: props => (
      //     <div className="flex items-center cursor-pointer">
      //       <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
      //         Last modified
      //       </Badge>
      //       <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
      //         {props.header.column.getIsSorted() === "asc" ? (
      //           <ArrowDown className="w-4 h-4" />
      //         ) : (
      //           <ArrowUp className="w-4 h-4" />
      //         )}
      //       </div>
      //     </div>
      //   ),
      //   cell: (props) => <span className="pl-4">{format(new Date(props.getValue()), "dd MMM yyyy")}</span>,
      //   enableSorting: true,
      //   size: 90,
      // }),

      columnHelper.accessor("totalDispatchInvoices", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Total Dispatch
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => <span className="pl-4">{props.getValue()}</span>,
        enableSorting: true,
        size: 90,
      }),

      columnHelper.accessor("totalReturnInvoices", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent text-text hover:bg-bg2-hover text-sm">
              Total Return
            </Badge>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => <span className="pl-4">{props.getValue()}</span>,
        enableSorting: true,
        size: 90,
      }),

      columnHelper.accessor("storage", {
        header: <span className="text-text">Storage Usage</span>,
        cell: (props) => (
          <div>
            <span>
              {formatFileSize(props.row.original.usedStorage, 0)} /{" "}
              {formatFileSize(props.getValue())}
            </span>
          </div>
        ),
        enableSorting: false,
        size: 100,
      }),

      columnHelper.accessor("credits", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <Badge className="py-[6px] px-4 bg-transparent hover:bg-bg2-hover text-text text-sm">
              Credit
            </Badge>
            <div className="p-2 rounded-full hover:bg-bg2-hover text-text transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4 text-text" />
              ) : (
                <ArrowUp className="w-4 h-4 text-text" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="px-4 flex items-center gap-x-2">
            <span>{props.getValue() ? props.getValue() : 0}</span>
            <div
              className="p-3 rounded-full hover:bg-bg2-hover text-text transition-all cursor-pointer"
              onClick={() => {
                setAllowCateCredit({
                  data: {
                    credits: Number(props.getValue()),
                    _id: props.row.original._id,
                  },
                  open: true,
                });
                setCredit((prevState) => ({
                  ...prevState,
                  current: Number(props.getValue()),
                }));
              }}
            >
              <Edit2 className="w-3 h-3" />
            </div>
          </div>
        ),
        sortingFn: (rowA, rowB) => {
          const pre = Number(rowA.original.credits ?? 0);
          const cur = Number(rowB.original.credits ?? 0);
          return pre < cur ? 1 : -1;
        },
        enableSorting: true,
        size: 180,
      }),

      columnHelper.display({
        id: "action",
        cell: (props) => (
          <div className="flex items-center gap-x-2">
            <Button
              // to={`history/${props.row.original._id}`}
              onClick={() => { navigate(`history/${props.row.original._id}`, { state: { name: props.row.original?.name } }) }}
              className="bg-primary text-white p-2 rounded-md"
            >
              View History
            </Button>
          </div>
        ),
        enableSorting: true,
        size: 180,
      }),

      columnHelper.display({
        id: "delete-account",
        cell: (props) => (
          <Button className="bg-transparent text-black hover:text-white" onClick={() => setDeleteAccount({ open: true, data: props.row.original })}>
            <Trash2 className="w-4 h-4" />
          </Button>
        ),
        enableSorting: true,
        size: 180,
      }),
    ],
    [plans.isLoading]
  );

  const handleSubmit = e => {
    e.preventDefault()
    toast.success("Account Deleted Sussessfully.")
    setDeleteAccount({ open: false, data: null })
  }

  const deleteUserAccount = useMutation({
    mutationFn: (values) => privateAdminAxios(DELETEUSERS, values),
    onSuccess: (res) => {
      // form.reset();
      setDeleteAccount({ open: false, data: null })
      refetch();
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const closeModal = () => {
    setAllowCateCredit({ open: false, data: { _id: null, credits: null } });
    setCredit({ add: null, current: null });
  };

  function closeConfigModal() {
    setUpdateConfig({ data: null, open: false });
  }

  const handleClose = () => {
    setDeleteAccount((prevState) => ({
      open: !prevState.open,
      data: null,
    }));
  };

  return isLoading || isCSVDowloading ? (
    <Loader />
  ) : (
    <>
      <div className="px-4 mt-4">
        <DataTable
          isFetching={isFetching}
          data={data}
          columns={columns}
          pagination
          sorting={sorting}
          setSorting={setSorting}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          sortableColumns={[
            "email",
            "name",
            "credits",
            "createdAt",
            "totalReturnInvoices",
            "totalDispatchInvoices",
            "updatedAt",
          ]}
          rowSelection={false}
          timeFillter
          downloadCSV
          downloadUserCSV={downloadUserCSV}
          enableRowSelection={false}
          pageSizes={[100, 150, 200, 250]}
          mainStyle="max-h-[655px]"
        />
      </div>

      <Dialog
        open={allowCateCredit.open}
        onOpenChange={closeModal}
        className="outline-none border-none"
      >
        <DialogContent closeIcon={false} className="bg-bg2 outline-none">
          <DialogHeader>
            <DialogTitle className="pb-4 flex justify-between items-center text-text text-2xl font-medium tracking-wider font-lato">
              <span>
                Allocate Credit{" "}
                <span className="text-base">
                  (
                  {isNaN(credit.current) || !credit.current
                    ? 0
                    : credit.current}{" "}
                  + {Number(credit.add)} ={" "}
                  {(isNaN(credit.current) || !credit.current
                    ? 0
                    : credit.current) + Number(credit.add)}
                  )
                </span>
              </span>
              <X className="w-6 h-6 cursor-pointer" onClick={closeModal} />
            </DialogTitle>
            <hr className="-mx-6 border-text3" />
            <DialogDescription className="pt-4">
              <form
                className="flex flex-col gap-y-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  mutate({
                    user_id: allowCateCredit.data._id,
                    credits: Number(credit.add),
                  });
                }}
              >
                <Input
                  type="number"
                  disabled
                  placeholder="Enter total credit"
                  className="bg-bg2-hover"
                  value={credit.current ? credit.current : 0}
                  onChange={({ target }) =>
                    setCredit((prevState) => ({
                      ...prevState,
                      current: Number(target.value),
                    }))
                  }
                />
                <Input
                  type="number"
                  placeholder="Add credit"
                  className="bg-bg2-hover"
                  value={credit.add ? credit.add : ""}
                  onChange={({ target }) =>
                    setCredit((prevState) => ({
                      ...prevState,
                      add: Number(target.value),
                    }))
                  }
                />
                <div className="flex justify-end items-center gap-x-4 mt-4">
                  <Button type="button" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      allowCateCredit.data.credits ===
                      credit.current + Number(credit.add) ||
                      (isNaN(credit.current) || !credit.current
                        ? 0
                        : credit.current) +
                      Number(credit.add) <
                      0
                    }
                  >
                    Save
                  </Button>
                </div>
              </form>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog open={updateConfig.open}>
        <DialogContent className="max-w-[500px] bg-bg-primary">
          <DialogTitle className="text-text text-2xl">
            Update Configuration
          </DialogTitle>
          <DialogDescription className="mt-4">
            <form
              className="flex flex-col gap-y-4"
              onSubmit={configuration.handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-y-1">
                <Label className="text-text text-base">
                  Credit Charge Per Month
                </Label>
                <Input
                  type="number"
                  step="any"
                  className={`py-5 border ${configuration.formState.errors.allocatePerMonthCredit &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                    }`}
                  autoComplete="off"
                  placeholder="Enter credit charge per month"
                  {...configuration.register("allocatePerMonthCredit")}
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <Label className="text-text text-base">
                  Credit Cost Per Dispacth
                </Label>
                <Input
                  type="number"
                  step="any"
                  className={`py-5 border ${configuration.formState.errors.dispatchRate &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                    }`}
                  autoComplete="off"
                  placeholder="Enter credit cost per dispacth"
                  {...configuration.register("dispatchRate")}
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <Label className="text-text text-base">
                  Credit Cost Per Return
                </Label>
                <Input
                  type="number"
                  step="any"
                  className={`py-5 border ${configuration.formState.errors.dispatchRate &&
                    "text-red-400 placeholder:text-red-600 border-red-400"
                    }`}
                  autoComplete="off"
                  placeholder="Enter credit cost per return"
                  {...configuration.register("returnRate")}
                />
              </div>

              <div className="flex flex-col gap-y-1">
                <Label className="text-text text-base">
                  Plan
                </Label>
                <Select value={configuration.watch("plan_id") ?? undefined} onValueChange={e => updatePlant.mutate({ plan_id: e, user_id: updateConfig.data._id })}>
                  <SelectTrigger className="bg-bg2-hover text-text">
                    <SelectValue placeholder="Select Plan" />
                  </SelectTrigger>
                  <SelectContent>
                    {plan.data && plan.data.map((plan_details) => {
                      return <SelectItem value={plan_details._id}>
                        {plan_details.plan_name}
                      </SelectItem>
                    })}
                  </SelectContent>
                </Select>
              </div>


              <div className="flex flex-col gap-y-1">
                <Label className="text-text text-lg">Storage</Label>
                <div className="flex">
                  <Input
                    step="any"
                    type="number"
                    className={`py-5 border-0 rounded-md rounded-r-none ${configuration.formState.errors.allocateStorage &&
                      "text-red-400 placeholder:text-red-600 border-red-400"
                      }`}
                    autoComplete="off"
                    placeholder="Enter storage"
                    {...configuration.register("allocateStorage")}
                  />
                  <Select
                    defaultValue={selectedFileFormat}
                    value={selectedFileFormat}
                    onValueChange={(e) => setSelectedFileFormat(e)}
                  >
                    <SelectTrigger className="w-max gap-2 rounded-l-none text-text bg-[#e6e7ea]">
                      <SelectValue
                        className="gap-2"
                        placeholder="Select Year"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {fileSizes.map((s, i) => {
                        return (
                          <SelectItem key={i} value={s}>
                            {s}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex justify-end gap-x-3">
                <Button type="button" onClick={closeConfigModal}>
                  Cancel
                </Button>
                <Button type="submit">Update</Button>
              </div>
            </form>
          </DialogDescription>
        </DialogContent>
      </Dialog>

      {/* Delete Account */}
      <Dialog open={deleteAccount.open} onOpenChange={handleClose}>
        <DialogContent
          closeIcon={false}
          className="w-full max-w-[550px] bg-bg-primary"
        >
          {/* <form
            // onSubmit={handleSubmit}
            className="flex flex-col gap-y-6"
          > */}
          <div className="size-[100px] rounded-full mx-auto flex justify-center items-center bg-[#0097CECC]">
            <Trash2 className="size-10 text-white" />
          </div>
          <div className="space-y-1">
            <h3 className="text-2xl text-center font-semibold">Delete Account?</h3>
            <p className="text-base text-text2 text-center">Are you sure you want to delete <strong className="text-black">{deleteAccount?.data?.name}'s</strong> account?</p>
            <div className="pt-2">
              <Textarea className="min-h-[100px] bg-transparent border-text outline-none focus:outline-none"
                placeholder="Please enter reason"
                onChange={(e) => { setReason(e.target.value) }}
              // {...(appSetting.register("versionMessage", { required: "This field is required" }))} 
              /></div>
          </div>
          <div className="flex justify-end gap-x-3">
            <Button className="w-full py-6 rounded-lg text-lg bg-[#0097CECC] hover:bg-bg2 hover:text-black" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="w-full py-6 rounded-lg text-lg text-black hover:text-white bg-bg2 hover:bg-[#0097CECC]" type="submit" onClick={() => { deleteUserAccount.mutate({ user_id: deleteAccount?.data?._id, reason: reason }) }}>Delete</Button>
          </div>
          {/* </form> */}
        </DialogContent>
      </Dialog>
      {/* Delete Account */}

    </>
  );
};

export default Dashboard;
