import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useEffect, useMemo, useState } from "react";
import DispatchContent from "../../components/history/DispatchContent";
import ReturnContent from "../../components/history/ReturnContent";
import { format } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PROFILE, STORAGE, USERPLAN } from "../../constant/endpoints";
import { useSelector } from "react-redux";
import { formatFileSize } from "../../lib/helper";
import { Button, buttonVariants } from "../../components/ui/button";
import { Link } from "react-router-dom";
import { cn } from "../../components/utils";
import { ArrowDown, ArrowUp, CalendarDays, CreditCard, Wallet2 } from "lucide-react";
import { privateAxios } from "../../lib/axios";
import { USER_PLAN_HISTORY } from '../../constant/endpoints'
import DataTable from "../../components/DataTable";
import { createColumnHelper } from "@tanstack/react-table";

const History = () => {
  const { plan_id, plan_activation_date, ...rest } = useSelector(
    (state) => state.userAuthReducer.auth
  );

  const defaultYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [activeTab, setActiveTab] = useState(1);
  const [planName, setPlanName] = useState("");
  const [paymentSorting, setPaymentSorting] = useState([]);

  const paymentHistory = useQuery({
    queryKey: [USER_PLAN_HISTORY],
    queryFn: () => privateAxios(USER_PLAN_HISTORY, { user_id: rest._id }),
  });


  useEffect(() => {
    if (plan_id) {
      queryClient
        .ensureQueryData({
          queryKey: [USERPLAN],
          queryFn: () => privateAxios(USERPLAN, null, "GET"),
          select: (res) => res.data,
        })
        .then((res) => {
          const activePlan = res.data.find((plan) => plan._id === plan_id);
          setPlanName(activePlan.plan_name);
        });
    } else {
      queryClient
        .ensureQueryData({
          queryKey: [USERPLAN],
          queryFn: () => privateAxios(USERPLAN, null, "GET"),
          select: (res) => res.data,
        })
        .then((res) => {
          setPlanName(res.data?.[0].plan_name);
        });
    }

    queryClient.invalidateQueries({ queryKey: [PROFILE] });
  }, []);

  const past10years = useMemo(() => {
    let years = [];
    const start = defaultYear;
    const end = 2023;
    years.push("All");
    for (let i = start; i >= end; i--) {
      years.push(i);
    }
    return years;
  }, []);

  const totaleStorage = useSelector(
    (state) => state.userAuthReducer.auth.storage
  );
  const {
    totalDispatchCredits,
    totalReturnCredits,
    credits,
    dispatchRate,
    returnRate,
  } = useSelector((state) => state.userAuthReducer.auth);
  const queryClient = useQueryClient();

  const { data } = queryClient.getQueryData({ queryKey: [STORAGE] }) ?? {};

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: [STORAGE] });
  }, []);

  const { totalDispatch, totalReturn } = useMemo(
    () => ({
      totalDispatch: data.find((values) => values._id === "Dispatch"),
      totalReturn: data.find((values) => values._id === "Return"),
    }),
    [data]
  );

  const totalUsedStorage = useMemo(
    () => Number(totalDispatch.storage) + Number(totalReturn.storage),
    [data]
  );

  const totalUsedStoragePercentage = useMemo(
    () => (totalUsedStorage * 100) / totaleStorage,
    [data, totalUsedStorage, totaleStorage]
  );

  const usedCreditsPercent = useMemo(
    () =>
      ((Number(
        totalDispatchCredits ? totalDispatchCredits : totalDispatchCredits
      ) +
        Number(totalReturnCredits ? totalReturnCredits : 0)) *
        100) /
      (Number(
        totalDispatchCredits ? totalDispatchCredits : totalDispatchCredits
      ) +
        Number(totalReturnCredits ? totalReturnCredits : 0) +
        Number(credits ? credits : 0)),
    []
  );

  const columnHelper = createColumnHelper();

  const historyColumn = useMemo(() => [
    columnHelper.accessor("order_id", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Order Id
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),

    columnHelper.accessor("payment_status", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Payment status
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),

    columnHelper.accessor("amount", {
      header: (props) => (
        <div className="flex items-center cursor-pointer">
          <div className="py-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            Payment status
          </div>
          <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
            {props.header.column.getIsSorted() === "asc" ? (
              <ArrowDown className="w-4 h-4" />
            ) : (
              <ArrowUp className="w-4 h-4" />
            )}
          </div>
        </div>
      ),
      cell: (props) => (
        <div className="py-2 flex items-center gap-x-4">
          {props.getValue()}
        </div>
      ),
      enableSorting: true,
      size: 525,
    }),
  ], [])

  return (
    <div className="p-4">
      <Tabs
        defaultValue="account"
        className="w-full relative"
        onValueChange={(e) => {
          setSelectedYear(defaultYear);
          setActiveTab(e === "account" ? 1 : e === "payment" ? 2 : 3);
        }}
      >
        <div className="w-full flex justify-between items-center border-b-2 border-text3 transition-all">
          <TabsList className="h-full p-0 bg-transparent">
            <TabsTrigger
              value="account"
              className="py-1 px-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Account Summary</span>
              <span className="text-text2">Usage Snapshot</span>
            </TabsTrigger>
            <TabsTrigger
              value="payment"
              className="py-1 px-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Payment History</span>
              <span className="text-text2">Payment for services</span>
            </TabsTrigger>
            <TabsTrigger
              value="usage"
              className="py-1 px-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:border-text3 rounded-t-md data-[state=active]:shadow-none"
            >
              <span className="text-lg text-primary">Usage History</span>
              <span className="text-text2">Periodic Billing Pattern</span>
            </TabsTrigger>
          </TabsList>
          <div className="flex items-center gap-x-2">
            {activeTab !== 1 && (
              <Select
                defaultValue={defaultYear}
                value={selectedYear}
                onValueChange={(e) => setSelectedYear(e)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Year" />
                </SelectTrigger>
                <SelectContent>
                  {past10years.map((years) => {
                    return (
                      <SelectItem key={years} value={years}>
                        {years}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            )}
          </div>
        </div>

        <TabsContent value="account" className="m-0 py-4 overflow-hidden">
          <div className="flex gap-y-6 flex-col">
            <div className="p-2 pe-4 pb-4 border-2 rounded-md">
              <h6 className="text-text font-medium">MY USAGE</h6>
              <p className="text-text2 text-sm">
                Current month to date usage for {format(new Date(), "LLL")}
              </p>

              <hr className="-mx-4 my-2" />

              <div className="flex justify-between items-stretch gap-10">
                <div className="w-full flex flex-col space-y-1">
                  <h6 className="font-medium text-text2 text-lg">
                    {formatFileSize(totalUsedStorage, 2)}
                  </h6>
                  <div className="h-6 bg-text3 relative">
                    <div
                      style={{ width: totalUsedStoragePercentage + "%" }}
                      className="absolute h-full bg-primary left-0 transition-all"
                    ></div>
                  </div>
                </div>
                <div className="w-full max-w-[485px] flex items-stretch gap-8">
                  <div className="w-full max-w-[85px] whitespace-nowrap text-sm flex flex-col justify-end items-end">
                    <p>Total Storage</p>
                    <p className="text-text font-medium whitespace-nowrap">
                      {formatFileSize(totaleStorage)}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <Link
                      to="/get-storage"
                      className={cn(
                        buttonVariants("default"),
                        "w-full bg-primary rounded-sm"
                      )}
                    >
                      Buy More Storage
                    </Link>
                  </div>
                </div>
              </div>

              <hr className="-mx-4 my-4" />

              <div className="flex justify-between items-stretch gap-10">
                <div className="w-full flex flex-col space-y-1">
                  <h6 className="font-medium text-text2 text-lg">
                    {Number(totalDispatchCredits) + Number(totalReturnCredits)
                      ? (Number(totalDispatchCredits) +
                        Number(totalReturnCredits)).toFixed(2)
                      : 0}
                    Credit
                  </h6>
                  <div className="h-6 bg-text3 relative">
                    <div
                      style={{ width: `${usedCreditsPercent}%` }}
                      className="absolute h-full bg-primary left-0 transition-all"
                    ></div>
                  </div>
                </div>
                <div className="w-full max-w-[485px] flex items-stretch gap-8">
                  <div className="w-full max-w-[85px] whitespace-nowrap text-sm flex flex-col justify-end items-end">
                    <p>Total Credit</p>
                    <p className="text-text font-medium whitespace-nowrap">
                      {(Number(credits ? credits : 0) +
                        Number(
                          totalDispatchCredits ? totalDispatchCredits : 0
                        ) +
                        Number(totalReturnCredits ? totalReturnCredits : 0)).toFixed(2)}
                    </p>
                  </div>
                  <div className="w-full flex items-center">
                    <Link
                      to="/get-credits"
                      className={cn(
                        buttonVariants("default"),
                        "w-full bg-primary rounded-sm"
                      )}
                    >
                      Buy More Credits
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 pe-4 border-2 rounded-md">
              <h6 className="text-text font-medium">MY PLAN</h6>
              <hr className="-mx-4 my-2" />
              <div className="py-2 flex justify-between">
                <div className="flex gap-x-2">
                  <CreditCard className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Current Plan</p>
                    <p className="h-6 font-medium">{planName}</p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <CalendarDays className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Activation Date</p>
                    <p className="font-medium">
                      {plan_activation_date
                        ? format(new Date(plan_activation_date), "dd MMM yyyy")
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <Wallet2 className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Price Per Dispatch</p>
                    <p className="font-medium">{dispatchRate} Credit</p>
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <Wallet2 className="w-5 h-5 text-text1" />
                  <div>
                    <p className="text-sm text-text2">Price Per Return</p>
                    <p className="font-medium">{returnRate} Credit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="payment" className="m-0 py-4">
          {(paymentHistory.data?.data && paymentHistory.data?.data.length > 0)
            ? <DataTable
              isFetching={paymentHistory.isFetching}
              data={paymentHistory.data.data}
              columns={historyColumn}
              pagination={false}
              sorting={paymentSorting}
              setSorting={setPaymentSorting}
              sortableColumns={["sortable", "totalInvoices", "totalCredits"]}
              enableSearch={false}
              enableRowSelection={false}
            />
            : <div className="h-[300px] flex items-center justify-center border-2 border-t-0">
              <h4 className="text-xl text-center">No data found</h4>
            </div>}
        </TabsContent>

        <TabsContent value="usage" className="m-0 py-4">
          <Tabs
            defaultValue="dispatch"
            className="w-full"
            onValueChange={() => setSelectedYear(defaultYear)}
          >
            <div className="pl-3 w-full border-b-2 border-text3 transition-all">
              <TabsList className="h-full p-0 bg-transparent">
                <TabsTrigger
                  value="dispatch"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Dispatch</span>
                </TabsTrigger>
                <TabsTrigger
                  value="return"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Return</span>
                </TabsTrigger>
                {/* <TabsTrigger
                  value="monthly"
                  className="p-2 -mb-[2px] flex flex-col items-start border-2 border-transparent border-b-0 data-[state=active]:text-primary data-[state=active]:border-text3 rounded-t-[4px] data-[state=active]:shadow-none"
                >
                  <span className="text-base">Monthly</span>
                </TabsTrigger> */}
              </TabsList>
            </div>
            <TabsContent value="dispatch" className="m-0 py-4">
              <DispatchContent selectedYear={selectedYear} />
            </TabsContent>
            <TabsContent value="return" className="m-0 py-4">
              <ReturnContent selectedYear={selectedYear} />
            </TabsContent>
          </Tabs>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default History;
