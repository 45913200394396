import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setIsAuthenticated, setUserAuth } from "../../redux/reducers/adminAuthSlice";
import { useQuery } from "@tanstack/react-query";
import { privateAdminAxios } from "../../lib/axios";
import { ADMINPROFILE } from "../../constant/endpoints";
import Loader from "../../components/Loader";
import usePaths from "../../hooks/usePaths";
import { useCallback, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { Calendar } from "../../components/ui/calendar";
import { Badge } from "../../components/ui/badge";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { endOfYear, format, getDate, getYear, isSameMonth, isSameYear, startOfYear, subDays } from "date-fns";
import { enUS } from "date-fns/locale";
import { Minus } from "lucide-react";
import { setEnd, setSelectedYear, setStart } from "../../redux/reducers/filterSlice";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { useMemo } from "react";

const Layout = () => {
  const isAuthenticated = useSelector(
    (state) => state.adminAuthReducer.isAuthenticated
  );
  console.log("isAuthenticated", isAuthenticated)
  const { selectedRows } = useSelector((data) => data.rowReducer);
  const { selectedYear } = useSelector((data) => data.filterReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { PATHS } = usePaths();

  const [filter, setFilter] = useState("Select Date");
  const [date, setDate] = useState({ from: null, to: null });
  const [isShowDates, setIsShowDates] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);

  const defaultYear = new Date().getFullYear();

  let shouldCallProfile = false;

  if (
    localStorage.getItem("adminToken") &&
    isAuthenticated &&
    localStorage.getItem("adminToken") === null
  ) {
    shouldCallProfile = false;
  } else {
    shouldCallProfile = true;
  }

  const { isLoading } = useQuery({
    queryKey: [ADMINPROFILE],
    queryFn: () => privateAdminAxios(ADMINPROFILE, null, "GET"),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        dispatch(setIsAuthenticated(true));
        dispatch(setUserAuth(res.data));
      } else {
        navigate("login");
      }
    },
    onError: (res) => {
      navigate("login");
    },
    enabled: shouldCallProfile,
    staleTime: Infinity,
  });

  const getPath = useCallback(() => {
    const path = Object.keys(PATHS).findLast((key) => pathname.includes(key));
    return (
      PATHS[path] ?? {
        title: "Vidscan Tech",
        isAddDropdown: false,
      }
    );
  },
    [pathname],
  )

  const handleApplyDateRange = () => {
    if (!date?.from) return toast.error("Please select start date");
    if (!date?.to) return toast.error("Please select end date");

    const { from, to } = date;

    if (from && to) {
      dispatch(setStart(from.toISOString()));
      dispatch(setEnd(to.toISOString()));
    }
    setIsDateFilter(false);
    setIsShowDates(true);
  };

  const handleClearAllFilter = () => {
    setIsDateFilter(false);
    setFilter("Select Date");
    setIsShowDates(false);
    setDate({ from: null, to: null });
    dispatch(setStart(undefined));
    dispatch(setEnd(undefined));
  };

  const handleFilterDateChange = (e) => {
    e === "Custom" ? setIsDateFilter(true) : setIsDateFilter(false);
    setFilter(e);
    switch (e) {
      case "Last 7 days":
        dispatch(setStart(subDays(new Date(), 7).toISOString()));
        dispatch(setEnd(new Date().toISOString()));
        // queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case "Last 30 days":
        dispatch(setStart(subDays(new Date(), 30).toISOString()));
        dispatch(setEnd(new Date().toISOString()));
        // queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case `This year (${new Date().getFullYear()})`:
        dispatch(setStart(startOfYear(new Date(new Date().getFullYear(), 0))));
        dispatch(setEnd(endOfYear(new Date(new Date().getFullYear(), 11))));
        // queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      case `Last year (${new Date().getFullYear() - 1})`:
        dispatch(
          setStart(startOfYear(new Date(new Date().getFullYear() - 1, 0)))
        );
        dispatch(setEnd(endOfYear(new Date(new Date().getFullYear() - 1, 11))));
        // queryClient.invalidateQueries({ queryKey: [GETIFOLDERDETAILS] });
        break;
      default:
        return null;
    }
  };

  const past10years = useMemo(() => {
    let years = [];
    const start = defaultYear;
    const end = 2023;
    for (let i = start; i >= end; i--) {
      years.push(i);
    }
    return years;
  }, []);

  return isLoading
    ? <Loader />
    : <>
      {(pathname !== "/admin/login" || !pathname.includes("/forget-password"))
        && <div className="flex items-center justify-between">
          <h3 className="py-2 px-4 flex items-center gap-x-3 text-text text-xl font-lato">
            {getPath().title}
          </h3>
          {getPath().isYearFilter && (
            <div className="w-max pl-1.5 pr-4 flex items-center justify-between overflow-x-auto">
              <Select
                defaultValue={defaultYear}
                value={selectedYear}
                onValueChange={(e) => dispatch(setSelectedYear(e))}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Year" />
                </SelectTrigger>
                <SelectContent>
                  {past10years.map((years) => {
                    return (
                      <SelectItem key={years} value={years}>
                        {years}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>}

      {getPath().isDateFilter
        && <div className={twMerge("h-[40px] mx-4 mt-3 flex items-center gap-x-2 transition-all", selectedRows.length && "bg-bg2 rounded-3xl px-1")}>
          <Select
            value={filter}
            onValueChange={handleFilterDateChange}
            onOpenChange={(e) => e && setIsDateFilter(false)}
          >
            <SelectTrigger className="w-max h-max py-1.5 pl-4  gap-x-3 bg-transparent text-text font-medium rounded-lg">
              <SelectValue placeholder={filter} />
            </SelectTrigger>
            <SelectContent className="bg-bg2 text-text">
              <SelectItem value="Select Date" className="hidden">
                Select Date
              </SelectItem>
              <SelectItem value="Last 7 days">
                Last 7 days
              </SelectItem>
              <SelectItem value="Last 30 days">
                Last 30 days
              </SelectItem>
              <SelectItem
                value={`This year (${new Date().getFullYear()})`}
              >
                This year ({new Date().getFullYear()})
              </SelectItem>
              <SelectItem
                value={`Last year (${new Date().getFullYear() - 1
                  })`}
              >
                Last year ({new Date().getFullYear() - 1})
              </SelectItem>
              <Popover open={isDateFilter} modal={true}>
                <SelectItem value="Custom">
                  <PopoverTrigger>
                    {isShowDates && date.to && date.from ? (
                      <p>
                        {isSameYear(date.to, date.from) ? (
                          isSameMonth(date.to, date.from) ? (
                            <div className="flex items-center gap-x-2">
                              <span>
                                {format(date.from, "LLL", {
                                  locale: enUS,
                                })}
                              </span>
                              <div className="flex items-center">
                                <span>{getDate(date.from)}</span>
                                <span>
                                  <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                                </span>
                                <span>{getDate(date.to)}</span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-x-2">
                              <span>
                                {format(date.from, "LLL", {
                                  locale: enUS,
                                })}{" "}
                                {getDate(date.from)}
                              </span>
                              <span>
                                <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                              </span>
                              <span>
                                {format(date.to, "LLL", {
                                  locale: enUS,
                                })}{" "}
                                {getDate(date.to)}
                              </span>
                            </div>
                          )
                        ) : (
                          <div className="flex items-center gap-x-2">
                            <span>
                              {format(date.from, "LLL", {
                                locale: enUS,
                              })}{" "}
                              {getDate(date.from)},{" "}
                              {getYear(date.from)}
                            </span>
                            <span>
                              <Minus className="w-2.5 h-3.5 mx-0.5 text-text" />
                            </span>
                            <span>
                              {format(date.to, "LLL", {
                                locale: enUS,
                              })}{" "}
                              {getDate(date.to)},{" "}
                              {getYear(date.to)}
                            </span>
                          </div>
                        )}
                      </p>
                    ) : (
                      "Custom"
                    )}
                  </PopoverTrigger>
                </SelectItem>
                <PopoverContent
                  align="start"
                  alignOffset={-20}
                  side="bottom"
                  sideOffset={10}
                  className="p-0 bg-bg2"
                >
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={new Date()}
                    selected={date}
                    onSelect={setDate}
                  />
                  <div className="px-3 pb-3 flex gap-x-3 justify-end">
                    <Button onClick={handleClearAllFilter}>
                      Cancel
                    </Button>
                    <Button onClick={handleApplyDateRange}>
                      Apply
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            </SelectContent>
          </Select>
          <Badge className="bg-bg2 hover:bg-bg2-hover text-text font-normal text-sm cursor-pointer" onClick={handleClearAllFilter}>
            Clear all
          </Badge>
        </div>}
      <Outlet />
    </>
}

export default Layout;
