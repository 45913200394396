import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateProfileSchema } from "../../lib/schemas";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import countyCode from "../../lib/countrycode.json";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { useMutation } from "@tanstack/react-query";
import { privateAdminAxios } from "../../lib/axios";
import {
  SENDOTP,
  ADMINUPDATEPROFILE,
  VERIFYOTP,
} from "../../constant/endpoints";
import { toastHandler } from "../../lib/helper";

const Profile = () => {
  const { phone, name, email, ccode } = useSelector(
    (state) => state.adminAuthReducer.auth
  );
  const [isOTPVerfier, setIsOTPVerfier] = useState(false);
  const [isCountrySelector, seIsCountrySelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(`+${ccode}`);
  const [OTP, setOTP] = useState("");

  let sholdVefifyOTP = false;

  const form = useForm({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: { phone, name, email },
  });

  if (
    phone !== form.getValues("phone") ||
    ccode !== selectedCountry.slice(1, selectedCountry.length)
  ) {
    sholdVefifyOTP = true;
  } else {
    sholdVefifyOTP = false;
  }

  const { mutate: sendOTP } = useMutation({
    mutationFn: (values) => privateAdminAxios(SENDOTP, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        setIsOTPVerfier(true);
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
    enabled: sholdVefifyOTP,
  });

  const { mutate: verifyOTP } = useMutation({
    mutationFn: (values) => privateAdminAxios(VERIFYOTP, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        updateProfile();
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
    enabled: sholdVefifyOTP,
  });

  const { mutate: editProfile } = useMutation({
    mutationFn: (values) => privateAdminAxios(ADMINUPDATEPROFILE, values),
    onSuccess: (res) => {
      if (res.ResponseCode === "1") {
        const { phone, name, email } = res.data;
        form.reset({ phone, name, email });
        setIsOTPVerfier();
        setOTP("");
      }
      return toastHandler(res);
    },
    onError: (res) => {
      return toastHandler(res);
    },
  });

  const onSubmit = (values) => {
    const ccode = selectedCountry.slice(1, selectedCountry.length);
    if (sholdVefifyOTP) {
      const data = { phone: values.phone, ccode };
      return sendOTP(data);
    } else {
      const { email, ...values } = form.getValues();
      return editProfile({
        ...values,
        ccode,
      });
    }
  };

  const handleVerifyOTP = () => {
    const OTPVerifier = {
      ccode: selectedCountry.slice(1, selectedCountry.length),
      phone: form.getValues("phone"),
      otp: OTP,
    };
    return verifyOTP(OTPVerifier);
  };

  const updateProfile = () => {
    const { email, ...values } = form.getValues();
    return editProfile({
      ...values,
      ccode: selectedCountry.slice(1, selectedCountry.length),
    });
  };

  return (
    <div className="w-full max-w-3xl h-full mx-auto flex flex-col justify-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          noValidate
          className="w-full h-max space-y-6"
        >
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel
                  htmlFor="mobileNumber"
                  className="text-text text-base font-lato"
                >
                  Mobile Number
                </FormLabel>
                <FormControl>
                  <div className="flex">
                    <Popover
                      open={isCountrySelector}
                      onOpenChange={() =>
                        seIsCountrySelector(!isCountrySelector)
                      }
                    >
                      <PopoverTrigger className="w-full max-w-[85px] px-4 flex items-center justify-between gap-x-1 bg-bg2-hover rounded-tl-lg rounded-bl-lg outline-none">
                        <span className="text-sm text-text">
                          {selectedCountry}
                        </span>
                        <ChevronDown className="w-4 h-4 text-text2" />
                      </PopoverTrigger>
                      <PopoverContent align="start" className="p-0">
                        <Command className="bg-bg2 text-text2">
                          <CommandInput
                            placeholder="Search country name"
                            className="placeholder:text-text2"
                          />
                          <CommandList>
                            <CommandEmpty className="py-4 text-center">
                              No country found
                            </CommandEmpty>
                            {countyCode.countries.map((country, index) => {
                              return (
                                <CommandItem
                                  key={index}
                                  className={twMerge(
                                    "aria-selected:bg-bg2-hover text-text aria-selected:text-text cursor-pointer",
                                    selectedCountry === country.dial &&
                                    "bg-bg2-hover aria-selected:bg-bg2-hover cursor-default"
                                  )}
                                  onSelect={() =>
                                    selectedCountry === country.dial
                                      ? null
                                      : (setSelectedCountry(country.dial),
                                        seIsCountrySelector(false))
                                  }
                                >
                                  {country.name}
                                </CommandItem>
                              );
                            })}
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <Input
                      id="mobileNumber"
                      type="number"
                      placeholder="Enter your mobile number"
                      {...field}
                      className="ps-0 rounded-tl-none rounded-bl-none border-transparent"
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex gap-x-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel className="text-text text-base font-lato">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button
            type="submit"
            className="w-full font-lato font-bold text-lg bg-primary transition-all disabled:cursor-not-allowed"
            disabled={!(form.formState.isDirty || sholdVefifyOTP)}
          >
            Update Profile
          </Button>

          <div className="flex flex-col justify-between items-center gap-y-1 text-center">
            <Link
              to="/admin/change-password"
              className="text-center text-primary text-base cursor-pointer font-semibold font-lato"
            >
              Change Password?
            </Link>
          </div>
        </form>
      </Form>

      <Dialog
        open={isOTPVerfier}
        onOpenChange={(e) => setIsOTPVerfier(!isOTPVerfier)}
      >
        <DialogContent closeIcon={true} className="bg-bg-primary">
          <DialogHeader className="space-y-4">
            <DialogTitle className="text-primary">
              OTP has been sent to {selectedCountry}
              {form.getValues("phone")}
            </DialogTitle>
            <Input
              placeholder="Enter OTP"
              value={OTP}
              onChange={({ target }) => setOTP(target.value)}
            />
            <div className="flex gap-3">
              <Button
                type="submit"
                className="w-full font-lato font-bold text-lg bg-primary"
                onClick={handleVerifyOTP}
              >
                Update
              </Button>
              <Button
                type="submit"
                className="w-full font-lato font-bold text-lg bg-primary"
                onClick={() =>
                  sendOTP({
                    phone: form.getValues("phone"),
                    ccode: selectedCountry.slice(1, selectedCountry.length),
                  })
                }
              >
                Resend
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Profile;
