import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const EmailProtectedRoute = ({ children }) => {
    const location = useLocation();
    const email = location.state?.email;

    if (!email) {
        return <Navigate to="/admin/forget-password" replace />;
    }

    return <>{children}</>;
};

export default EmailProtectedRoute;
