import * as z from "zod";
import isEmail from "validator/lib/isEmail";

const loginSchema = z.object({
  email: z
    .string()
    .refine((e) => isEmail(e) || (e.length > 7 && e.length <= 17), {
      message: "please enter valid email or mobile number",
    }),
  password: z.string().min(6, {
    message: "password must be at least 6 characters",
  }),
});

const signupSchema = z
  .object({
    phone: z
      .string()
      .min(7, { message: "please enter valid mobile number" })
      .max(17, { message: "please enter valid mobile number" }),
    name: z.string().min(3, {
      message: "name must be at least 3 characters",
    }),
    email: z.string().trim().refine(isEmail, {
      message: "please enter valid email",
    }),
    gst: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (val === "" || val.trim() === "") {
            return true;
          } else {
            const pattern = new RegExp(
              "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
            );
            return pattern.test(val);
          }
        },
        {
          message: "please enter valid gst number",
        }
      ),
    company: z.string().optional(),
    password: z.string().min(6, {
      message: "password must be at least 6 characters",
    }),
    confirm_password: z.string(),
    isPolicyAccepted: z.literal(true, {
      errorMap: () => ({
        message: "please accept terms and condition to continue",
      }),
    }),
  })
  .refine(({ confirm_password, password }) => confirm_password === password, {
    message: "Password does't match",
    path: ["confirm_password"],
  });

const updateProfileSchema = z.object({
  phone: z
    .string()
    .min(7, { message: "please enter valid mobile number" })
    .max(17, { message: "please enter valid mobile number" }),
  name: z.string().min(3, { message: "name must be at least 3 characters" }),
  email: z.string().refine(isEmail, { message: "please enter valid email" }),
  gst: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (val === "" || val.trim() === "") {
          return true;
        } else {
          const pattern = new RegExp(
            "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
          );
          return pattern.test(val);
        }
      },
      {
        message: "please enter valid gst number",
      }
    ),
  company: z.string().optional(),
});

const updatePasswordSchema = z
  .object({
    old_password: z.string().min(6, {
      message: "password must be at least 6 characters",
    }),
    new_password: z.string().min(6, {
      message: "password must be at least 6 characters",
    }),
    confirm_new_password: z.string(),
  })
  .refine(
    ({ confirm_new_password, new_password }) =>
      confirm_new_password === new_password,
    {
      message: "Password does't match",
      path: ["confirm_new_password"],
    }
  );

const forgotPasswordSchema = z.object({
  email: z
    .string()
    .trim()
    .nonempty("Email is required")
    .email("Please enter valid email"),
});

const confirmPasswordSchema = z
  .object({
    new_password: z.string().min(6, {
      message: "new password must be at least 6 characters",
    }),
    confirm_new_password: z.string(),
  })
  .refine(
    ({ confirm_new_password, new_password }) =>
      confirm_new_password === new_password,
    {
      message: "Confirm new password does't match",
      path: ["confirm_new_password"],
    }
  );

const createNewFolder = z.object({
  displayName: z.string("please enter folder name").trim().nonempty(),
});

const addNewSubuser = z.object({
  phone: z
    .string()
    .trim()
    .min(7)
    .max(17)
    .nonempty("please enter valid mobile number"),
  password: z.string().trim().min(6, "password must be at least 6 characters"),
});

const resetPassword = z
  .object({
    new_password: z
      .string()
      .trim()
      .min(6, "Password must be at least 6 characters")
      .nonempty("Password is required"),
    confirm_new_password: z
      .string()
      .trim()
      .nonempty("Confirm password is required"),
  })
  .refine(
    ({ confirm_new_password, new_password }) =>
      confirm_new_password === new_password,
    {
      message: "Confirm new password does't match",
      path: ["confirm_new_password"],
    }
  );

const plans = z.object({
  plan_id: z.string().trim().min(1, "Plan id is required"),
  plan_name: z.string().trim().min(1, "Plan name is required"),
  price: z.string().trim().min(1, "Plan name is required"),
  storage: z.string().trim().min(1, "storage name is required"),
  credits: z.string().trim().min(1, "credits name is required"),
  share_upto: z.string().trim().min(1, "Share upto name is required"),
  description: z.array(
    z.object({ value: z.string().trim().min(1, "Description is required") })
  ),
});

const configuration = z.object({
  allocatePerMonthCredit: z.string().trim().optional(),
  dispatchRate: z.string().trim().optional(),
  allocateStorage: z.string().trim().optional(),
  returnRate: z.string().trim().optional(),
  plan_id: z.string().trim().optional(),
});

const barcode = z.object({
  barcode: z.string().trim().min(1, "Barcode id is required"),
});

const otpSchema = z.object({
  otp: z.string().trim().min(4,"Password is required")
})

// const shareCsv = z.object({
//   email:z.string().required("Email is required")
// })

export {
  loginSchema,
  signupSchema,
  updateProfileSchema,
  updatePasswordSchema,
  forgotPasswordSchema,
  confirmPasswordSchema,
  createNewFolder,
  addNewSubuser,
  resetPassword,
  plans,
  configuration,
  barcode,
  otpSchema
};
