import { useQuery } from "@tanstack/react-query";
import { CREDITS } from "../../constant/endpoints";
import { privateAxios } from "../../lib/axios";
import Loader from "../../components/Loader";
import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import DataTable from "../DataTable";
import { useState } from "react";
import { ArrowDown, ArrowUp, ChevronRight, Dot } from "lucide-react";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import moment from "moment/moment";

const ReturnContent = ({ selectedYear }) => {
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState({});

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [CREDITS, selectedYear],
    queryFn: () =>
      privateAxios(CREDITS, {
        ...(selectedYear != "All" && { year: selectedYear.toString() }),
        ...(selectedYear == "All" && { isAll: true }),
        type: "Return",
      }),
    select: (res) => {
      // we are clongin month field and days field for better sorting
      const clonedData = res.data.map(obj => {
        const subRows = []
        if (obj.subRows && Array.isArray(obj.subRows) && obj.subRows.length) {
          obj.subRows.forEach(sr => {
            subRows.push({
              ...sr,
              sortable: sr.day
            })
          })
          return {
            ...obj,
            subRows,
            sortable: obj.month
          }
        }
      })
      return clonedData
    },
  });


  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "expand",
        header: (props) => (
          <div
            className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer"
            onClick={() =>
              props.table.toggleAllRowsExpanded(
                !props.table.getIsSomeRowsExpanded()
              )
            }
          >
            <ChevronRight
              className={twMerge(
                "w-5 h-5 rounded-full transition-all",
                props.table.getIsSomeRowsExpanded() ? "rotate-90" : "rotate-0"
              )}
            />
          </div>
        ),
        cell: (props) =>
          props.row.depth === 0 ? (
            <div
              className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer"
              onClick={props.row.getToggleExpandedHandler()}
            >
              <ChevronRight
                className={twMerge(
                  "w-5 h-5 rounded-full transition-all",
                  props.row.getIsExpanded() ? "rotate-90" : "rotate-0"
                )}
              />
            </div>
          ) : (
            <div className="w-max p-2 hover:bg-bg-primary rounded-full aspect-square cursor-pointer">
              <Dot className={twMerge("w-5 h-5 rounded-full")} />
            </div>
          ),
        size: 48,
        enableExpanding: true,
      }),

      columnHelper.accessor("sortable", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Month
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="py-2 flex items-center gap-x-4">
            <span>
              {/* {moment().month(props.row.original.month - 1).format("MMMM")} */}
              {/* {props.row.original.month} */}
              {props.row.original.day
                ? props.row.original.day
                : moment().month(props.row.original.month - 1).format("MMMM")}
              {!props.row.original.day && `, ${selectedYear}`}
            </span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),

      columnHelper.accessor("totalInvoices", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Invoices
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),

      columnHelper.accessor("totalCredits", {
        header: (props) => (
          <div className="flex items-center cursor-pointer">
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              Credits
            </div>
            <div className="p-2 rounded-full text-text hover:bg-bg2-hover transition-all cursor-pointer">
              {props.header.column.getIsSorted() === "asc" ? (
                <ArrowDown className="w-4 h-4" />
              ) : (
                <ArrowUp className="w-4 h-4" />
              )}
            </div>
          </div>
        ),
        cell: (props) => (
          <div className="pl-4 flex items-center gap-x-4">
            <span>{props.getValue()}</span>
          </div>
        ),
        enableSorting: true,
        size: 525,
      }),
    ],
    [selectedYear]
  );

  return isLoading ? (
    <Loader />
  ) : (
    data && (
      <DataTable
        isFetching={isFetching}
        data={data}
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        sorting={sorting}
        setSorting={setSorting}
        expanded={expanded}
        setExpanded={setExpanded}
        sortableColumns={["sortable", "totalInvoices", "totalCredits"]}
        enableSearch={false}
      />
    )
  );
};

export default ReturnContent;
