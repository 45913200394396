import React from 'react'
import Logo from '../../assets/images/logoWithSlogan.jpg'
import OTPInput from 'react-otp-input'
import { useFormik } from 'formik'
import { Button } from '../../components/ui/button'
import { otpSchema } from '../../lib/schemas'
import { useMutation } from '@tanstack/react-query'
import { publibAxios } from '../../lib/axios'
import { ADMINVERIFY_OTP } from '../../constant/endpoints'
import { useLocation, useNavigate } from 'react-router-dom'
import { toastHandler } from '../../lib/helper'

const VerifyOtp = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const formik = useFormik({
        initialValues: {
            otp: "",
        },
        // validationSchema: otpSchema,
        onSubmit: (values) => {
            console.log("values", values)
        }
    })

    const { mutate: handleVerifyOTP } = useMutation({
        mutationFn: (values) => publibAxios(ADMINVERIFY_OTP, values),
        onSuccess: (res) => {
            if (res.ResponseCode === "1") {
                navigate('/admin/reset-password', { state: { email: state?.email } })
            }
            return toastHandler(res)
        },
        onError: (res) => {
            return toastHandler(res);
        },
    })
    return (
        <div className='w-full max-w-sm h-screen mx-auto flex flex-col justify-center'>
            <div className='space-y-10'>
                <div className='space-y-2'>
                    <div className='max-w-[350px] mx-auto mb-6'>
                        <img src={Logo} alt="logo" />
                    </div>
                    <h1 className='text-primary text-2xl font-lato font-semibold'>OTP Verification</h1>
                    <p className='text-text2'> Please enter the verification code sent on your registered email
                        address.</p>
                </div>
                <div className='w-full space-y-8'>
                    <div className="flex flex-col space-y-2">
                        <OTPInput
                            numInputs={4}
                            value={formik.values.otp}
                            onChange={(value) => {
                                formik.setFieldValue("otp", value); // Update formik value
                            }}
                            skipDefaultStyles
                            containerStyle="justify-start gap-x-5 mt-7"
                            renderInput={(props) => {
                                return (
                                    <input
                                        {...props}
                                        type="number"
                                        className="w-full max-w-[80px] h-[60px] text-base text-center  font-semibold outline-none border-bg2 bg-bg2-hover rounded-[5px]"
                                    />
                                );
                            }} />
                        {formik.touched.otp && formik.errors.otp ? (
                            <div className="text-red-500 mt-2 text-start">
                                {formik.errors.otp}
                            </div>
                        ) : null}
                    </div>
                    <Button className="w-full" onClick={() => { handleVerifyOTP({ email: state?.email, otp: formik.values.otp }) }}>Verify</Button>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp